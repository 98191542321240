@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Poppins;
    }
}